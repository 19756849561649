/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';
@import '../_inspector.scss';

$inspector-element-padding: 1.5%;
$row-identifier-width: 30%;
$small-input-width: 20%;
$small-input-min: 30px;
$medium-input-width: calc(100% - #{$row-identifier-width} - #{$small-input-width});
$large-input-width: calc(100% - #{$row-identifier-width});

.inspector-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: $inspector-row-height;
  margin-bottom: 4px;
}
.inspector-row:last-child {
  margin-bottom: 0px;
}

.inspector-row-identifier {
  display: flex;
  flex-direction: column;
  width: $row-identifier-width;
  max-width: $row-identifier-width;
  height: 100%;
  color: $editor-primary-property-text;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-align: right;
  padding-right: $inspector-element-padding;
  padding-left: $inspector-element-padding;
  margin-top: 3px;
}
.inspector-row-identifier:first-child {
  padding-left: 0;
}

.inspector-small-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: #{$small-input-width};
  min-width: 30px;
  height: 90%;
  //padding-right: $inspector-element-padding;
  padding-left: $inspector-element-padding;
}
.inspector-small-input-container:last-child {
  padding-right: 0;
}

.inspector-medium-input-container {
  width: $medium-input-width;
  padding-right: 0;
  padding-left: $inspector-element-padding;

}

.inspector-large-input-container {
  width: $large-input-width;
  padding-right: 0;
  padding-left: $inspector-element-padding;

}

.inspector-input-element {
  display: inline-block;
  height: 100%;
  width: 100%;
}