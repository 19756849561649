/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.canvas-transforms-widget {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  background-color: #191919;
  border-radius: 4px; }

.canvas-transforms-widget.mobile {
  margin-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

#canvas-zoom-options-container {
  display: flex;
  flex-direction: row;
  align-items: center; }

#zoom-tool-container {
  width: 40px;
  height: 40px; }

.canvas-transform-wick-button {
  border-radius: 0px; }

.zoom-tool {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.canvas-transform-item {
  padding: 2px; }

.canvas-transform-button {
  width: 40px;
  height: 40px;
  padding: 3px;
  padding-left: 1.5px;
  padding-right: 1.5px; }

.thin-transform-button {
  width: calc(40px * .6); }

.zoom-out-button {
  padding-left: 0px; }

.zoom-in-button {
  padding-right: 0px; }

.canvas-transform-icon {
  width: 90%; }

.play-button {
  width: 100%;
  height: 100%;
  padding: 3px; }

.play-button-container {
  width: calc(40px * 1.5);
  height: calc(40px * 1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191919;
  border-radius: 30px; }

.transforms-container {
  display: flex;
  flex-direction: row;
  align-items: center; }

.transforms-container:first-child {
  padding-left: 2px; }

.delete-copy-paste-widget {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 40px;
  margin-right: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background-color: #191919;
  border-radius: 4px; }

.delete-copy-paste-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2px; }

.disabled {
  opacity: 0.25; }
