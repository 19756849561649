/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

 @import 'Editor/_wickbrand.scss';

.object-info-title {
    color: #FFFFFF;//$editor-modal-text-gray; 
    font-size: 16px; 
  }
  
.object-info-row {
    color: #FFFFFF;//$editor-modal-text-gray; 
    font-size: 14px; 
    margin-top: 3px;
    display: flex; 
  }
  
  .object-info-row-icon {
    float: left; 
    width: 15px; 
    height: 15px;
    position: relative; 
    top: 2px;
    left: 0; 
  }
  
.object-info-row-text {
  margin-left: 4px; 
  color: #FFFFFF;//$wick-gray-text-light; 
}

.object-info-container {
    width: 100%; 
    height: 140px;
    background-color: $editor-modal-gray-secondary; 
    border-radius: 3px; 
    margin-top: 10px; 
    padding: 20px; 
    padding-top: 10px; 
  }