@import url('https://fonts.googleapis.com/css?family=Abel');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
@import url('https://fonts.googleapis.com/css?family=Allerta');
@import url('https://fonts.googleapis.com/css?family=Allerta+Stencil');
@import url('https://fonts.googleapis.com/css?family=Almendra+SC');
@import url('https://fonts.googleapis.com/css?family=Amatic+SC');
@import url('https://fonts.googleapis.com/css?family=Annie+Use+Your+Telescope');
@import url('https://fonts.googleapis.com/css?family=Antic+Didone');
@import url('https://fonts.googleapis.com/css?family=Antic+Slab');
@import url('https://fonts.googleapis.com/css?family=Anton');
@import url('https://fonts.googleapis.com/css?family=Architects+Daughter');
@import url('https://fonts.googleapis.com/css?family=Archivo+Narrow');
@import url('https://fonts.googleapis.com/css?family=Arimo');
@import url('https://fonts.googleapis.com/css?family=Arvo');
@import url('https://fonts.googleapis.com/css?family=Assistant');
@import url('https://fonts.googleapis.com/css?family=Average');
@import url('https://fonts.googleapis.com/css?family=Balthazar');
@import url('https://fonts.googleapis.com/css?family=Belgrano');
@import url('https://fonts.googleapis.com/css?family=Belleza');
@import url('https://fonts.googleapis.com/css?family=Bitter');
@import url('https://fonts.googleapis.com/css?family=Boogaloo');
@import url('https://fonts.googleapis.com/css?family=Bubbler+One');
@import url('https://fonts.googleapis.com/css?family=Cabin');
@import url('https://fonts.googleapis.com/css?family=Cagliostro');
@import url('https://fonts.googleapis.com/css?family=Cairo');
@import url('https://fonts.googleapis.com/css?family=Cambo');
@import url('https://fonts.googleapis.com/css?family=Candal');
@import url('https://fonts.googleapis.com/css?family=Catamaran');
@import url('https://fonts.googleapis.com/css?family=Ceviche+One');
@import url('https://fonts.googleapis.com/css?family=Chela+One');
@import url('https://fonts.googleapis.com/css?family=Cinzel');
@import url('https://fonts.googleapis.com/css?family=Contrail+One');
@import url('https://fonts.googleapis.com/css?family=Convergence');
@import url('https://fonts.googleapis.com/css?family=Crimson+Text');
@import url('https://fonts.googleapis.com/css?family=Damion');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Domine');
@import url('https://fonts.googleapis.com/css?family=Dorsa');
@import url('https://fonts.googleapis.com/css?family=Dosis');
@import url('https://fonts.googleapis.com/css?family=Erica+One');
@import url('https://fonts.googleapis.com/css?family=Euphoria+Script');
@import url('https://fonts.googleapis.com/css?family=Fauna+One');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed');
@import url('https://fonts.googleapis.com/css?family=Fjalla+One');
@import url('https://fonts.googleapis.com/css?family=Francois+One');
@import url('https://fonts.googleapis.com/css?family=Fresca');
@import url('https://fonts.googleapis.com/css?family=Fugaz+One');
@import url('https://fonts.googleapis.com/css?family=Gafata');
@import url('https://fonts.googleapis.com/css?family=Galdeano');
@import url('https://fonts.googleapis.com/css?family=Geostar+Fill');
@import url('https://fonts.googleapis.com/css?family=Germania+One');
@import url('https://fonts.googleapis.com/css?family=Gilda+Display');
@import url('https://fonts.googleapis.com/css?family=Gloria+Hallelujah');
@import url('https://fonts.googleapis.com/css?family=Goblin+One');
@import url('https://fonts.googleapis.com/css?family=Gochi+Hand');
@import url('https://fonts.googleapis.com/css?family=Graduate');
@import url('https://fonts.googleapis.com/css?family=Habibi');
@import url('https://fonts.googleapis.com/css?family=Hind+Madurai');
@import url('https://fonts.googleapis.com/css?family=Hind+Siliguri');
@import url('https://fonts.googleapis.com/css?family=Holtwood+One+SC');
@import url('https://fonts.googleapis.com/css?family=Homenaje');
@import url('https://fonts.googleapis.com/css?family=Iceberg');
@import url('https://fonts.googleapis.com/css?family=Iceland');
@import url('https://fonts.googleapis.com/css?family=Imprima');
@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import url('https://fonts.googleapis.com/css?family=Inder');
@import url('https://fonts.googleapis.com/css?family=Indie+Flower');
@import url('https://fonts.googleapis.com/css?family=Italiana');
@import url('https://fonts.googleapis.com/css?family=Julee');
@import url('https://fonts.googleapis.com/css?family=Kanit');
@import url('https://fonts.googleapis.com/css?family=Keania+One');
@import url('https://fonts.googleapis.com/css?family=Kite+One');
@import url('https://fonts.googleapis.com/css?family=Knewave');
@import url('https://fonts.googleapis.com/css?family=Kotta+One');
@import url('https://fonts.googleapis.com/css?family=Krona+One');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Lemon');
@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+128');
@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39');
@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended');
@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text');
@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39+Text');
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin');
@import url('https://fonts.googleapis.com/css?family=Lilita+One');
@import url('https://fonts.googleapis.com/css?family=Lily+Script+One');
@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Loved+by+the+King');
@import url('https://fonts.googleapis.com/css?family=Lustria');
@import url('https://fonts.googleapis.com/css?family=Macondo+Swash+Caps');
@import url('https://fonts.googleapis.com/css?family=Marko+One');
@import url('https://fonts.googleapis.com/css?family=Mate+SC');
@import url('https://fonts.googleapis.com/css?family=Medula+One');
@import url('https://fonts.googleapis.com/css?family=Merienda+One');
@import url('https://fonts.googleapis.com/css?family=Montaga');
@import url('https://fonts.googleapis.com/css?family=Mr+Bedfort');
@import url('https://fonts.googleapis.com/css?family=Mukta');
@import url('https://fonts.googleapis.com/css?family=News+Cycle');
@import url('https://fonts.googleapis.com/css?family=Nothing+You+Could+Do');
@import url('https://fonts.googleapis.com/css?family=Noticia+Text');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
@import url('https://fonts.googleapis.com/css?family=Numans');
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
@import url('https://fonts.googleapis.com/css?family=Offside');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Overlock+SC');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Passero+One');
@import url('https://fonts.googleapis.com/css?family=Pathway+Gothic+One');
@import url('https://fonts.googleapis.com/css?family=Patua+One');
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
@import url('https://fonts.googleapis.com/css?family=Plaster');
@import url('https://fonts.googleapis.com/css?family=Play');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url('https://fonts.googleapis.com/css?family=Poller+One');
@import url('https://fonts.googleapis.com/css?family=Pompiere');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Port+Lligat+Sans');
@import url('https://fonts.googleapis.com/css?family=Port+Lligat+Slab');
@import url('https://fonts.googleapis.com/css?family=Prociono');
@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('https://fonts.googleapis.com/css?family=Quicksand');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Rammetto+One');
@import url('https://fonts.googleapis.com/css?family=Righteous');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Rochester');
@import url('https://fonts.googleapis.com/css?family=Ropa+Sans');
@import url('https://fonts.googleapis.com/css?family=Ruluko');
@import url('https://fonts.googleapis.com/css?family=Sail');
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light');
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two');
@import url('https://fonts.googleapis.com/css?family=Slabo+27px');
@import url('https://fonts.googleapis.com/css?family=Slackey');
@import url('https://fonts.googleapis.com/css?family=Sofia');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
@import url('https://fonts.googleapis.com/css?family=Spinnaker');
@import url('https://fonts.googleapis.com/css?family=Squada+One');
@import url('https://fonts.googleapis.com/css?family=Strait');
@import url('https://fonts.googleapis.com/css?family=Tauri');
@import url('https://fonts.googleapis.com/css?family=Telex');
@import url('https://fonts.googleapis.com/css?family=Text+Me+One');
@import url('https://fonts.googleapis.com/css?family=Titillium+Web');
@import url('https://fonts.googleapis.com/css?family=Trykker');
@import url('https://fonts.googleapis.com/css?family=Tulpen+One');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Condensed');
@import url('https://fonts.googleapis.com/css?family=Unlock');
@import url('https://fonts.googleapis.com/css?family=Vampiro+One');
@import url('https://fonts.googleapis.com/css?family=Viga');
@import url('https://fonts.googleapis.com/css?family=Vollkorn');
@import url('https://fonts.googleapis.com/css?family=Voltaire');
@import url('https://fonts.googleapis.com/css?family=Wendy+One');
@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz');

.font-selector-Abel{font-family:'Abel','Arial';}
.font-selector-Abril-Fatface{font-family:'Abril Fatface','Arial';}
.font-selector-Allerta{font-family:'Allerta','Arial';}
.font-selector-Allerta-Stencil{font-family:'Allerta Stencil','Arial';}
.font-selector-Almendra-SC{font-family:'Almendra SC','Arial';}
.font-selector-Amatic-SC{font-family:'Amatic SC','Arial';}
.font-selector-Annie-Use-Your-Telescope{font-family:'Annie Use Your Telescope','Arial';}
.font-selector-Antic-Didone{font-family:'Antic Didone','Arial';}
.font-selector-Antic-Slab{font-family:'Antic Slab','Arial';}
.font-selector-Anton{font-family:'Anton','Arial';}
.font-selector-Architects-Daughter{font-family:'Architects Daughter','Arial';}
.font-selector-Archivo-Narrow{font-family:'Archivo Narrow','Arial';}
.font-selector-Arimo{font-family:'Arimo','Arial';}
.font-selector-Arvo{font-family:'Arvo','Arial';}
.font-selector-Assistant{font-family:'Assistant','Arial';}
.font-selector-Average{font-family:'Average','Arial';}
.font-selector-Balthazar{font-family:'Balthazar','Arial';}
.font-selector-Belgrano{font-family:'Belgrano','Arial';}
.font-selector-Belleza{font-family:'Belleza','Arial';}
.font-selector-Bitter{font-family:'Bitter','Arial';}
.font-selector-Boogaloo{font-family:'Boogaloo','Arial';}
.font-selector-Bubbler-One{font-family:'Bubbler One','Arial';}
.font-selector-Cabin{font-family:'Cabin','Arial';}
.font-selector-Cagliostro{font-family:'Cagliostro','Arial';}
.font-selector-Cairo{font-family:'Cairo','Arial';}
.font-selector-Cambo{font-family:'Cambo','Arial';}
.font-selector-Candal{font-family:'Candal','Arial';}
.font-selector-Catamaran{font-family:'Catamaran','Arial';}
.font-selector-Ceviche-One{font-family:'Ceviche One','Arial';}
.font-selector-Chela-One{font-family:'Chela One','Arial';}
.font-selector-Cinzel{font-family:'Cinzel','Arial';}
.font-selector-Contrail-One{font-family:'Contrail One','Arial';}
.font-selector-Convergence{font-family:'Convergence','Arial';}
.font-selector-Crimson-Text{font-family:'Crimson Text','Arial';}
.font-selector-Damion{font-family:'Damion','Arial';}
.font-selector-Dancing-Script{font-family:'Dancing Script','Arial';}
.font-selector-Domine{font-family:'Domine','Arial';}
.font-selector-Dorsa{font-family:'Dorsa','Arial';}
.font-selector-Dosis{font-family:'Dosis','Arial';}
.font-selector-Erica-One{font-family:'Erica One','Arial';}
.font-selector-Euphoria-Script{font-family:'Euphoria Script','Arial';}
.font-selector-Fauna-One{font-family:'Fauna One','Arial';}
.font-selector-Fira-Sans-Condensed{font-family:'Fira Sans Condensed','Arial';}
.font-selector-Fjalla-One{font-family:'Fjalla One','Arial';}
.font-selector-Francois-One{font-family:'Francois One','Arial';}
.font-selector-Fresca{font-family:'Fresca','Arial';}
.font-selector-Fugaz-One{font-family:'Fugaz One','Arial';}
.font-selector-Gafata{font-family:'Gafata','Arial';}
.font-selector-Galdeano{font-family:'Galdeano','Arial';}
.font-selector-Geostar-Fill{font-family:'Geostar Fill','Arial';}
.font-selector-Germania-One{font-family:'Germania One','Arial';}
.font-selector-Gilda-Display{font-family:'Gilda Display','Arial';}
.font-selector-Gloria-Hallelujah{font-family:'Gloria Hallelujah','Arial';}
.font-selector-Goblin-One{font-family:'Goblin One','Arial';}
.font-selector-Gochi-Hand{font-family:'Gochi Hand','Arial';}
.font-selector-Graduate{font-family:'Graduate','Arial';}
.font-selector-Habibi{font-family:'Habibi','Arial';}
.font-selector-Hind-Madurai{font-family:'Hind Madurai','Arial';}
.font-selector-Hind-Siliguri{font-family:'Hind Siliguri','Arial';}
.font-selector-Holtwood-One-SC{font-family:'Holtwood One SC','Arial';}
.font-selector-Homenaje{font-family:'Homenaje','Arial';}
.font-selector-Iceberg{font-family:'Iceberg','Arial';}
.font-selector-Iceland{font-family:'Iceland','Arial';}
.font-selector-Imprima{font-family:'Imprima','Arial';}
.font-selector-Inconsolata{font-family:'Inconsolata','Arial';}
.font-selector-Inder{font-family:'Inder','Arial';}
.font-selector-Indie-Flower{font-family:'Indie Flower','Arial';}
.font-selector-Italiana{font-family:'Italiana','Arial';}
.font-selector-Julee{font-family:'Julee','Arial';}
.font-selector-Kanit{font-family:'Kanit','Arial';}
.font-selector-Keania-One{font-family:'Keania One','Arial';}
.font-selector-Kite-One{font-family:'Kite One','Arial';}
.font-selector-Knewave{font-family:'Knewave','Arial';}
.font-selector-Kotta-One{font-family:'Kotta One','Arial';}
.font-selector-Krona-One{font-family:'Krona One','Arial';}
.font-selector-Lato{font-family:'Lato','Arial';}
.font-selector-Lemon{font-family:'Lemon','Arial';}
.font-selector-Libre-Barcode-128{font-family:'Libre Barcode 128','Arial';}
.font-selector-Libre-Barcode-39{font-family:'Libre Barcode 39','Arial';}
.font-selector-Libre-Barcode-39-Extended{font-family:'Libre Barcode 39 Extended','Arial';}
.font-selector-Libre-Barcode-39-Extended-Text{font-family:'Libre Barcode 39 Extended Text','Arial';}
.font-selector-Libre-Barcode-39-Text{font-family:'Libre Barcode 39 Text','Arial';}
.font-selector-Libre-Franklin{font-family:'Libre Franklin','Arial';}
.font-selector-Lilita-One{font-family:'Lilita One','Arial';}
.font-selector-Lily-Script-One{font-family:'Lily Script One','Arial';}
.font-selector-Lobster{font-family:'Lobster','Arial';}
.font-selector-Loved-by-the-King{font-family:'Loved by the King','Arial';}
.font-selector-Lustria{font-family:'Lustria','Arial';}
.font-selector-Macondo-Swash-Caps{font-family:'Macondo Swash Caps','Arial';}
.font-selector-Marko-One{font-family:'Marko One','Arial';}
.font-selector-Mate-SC{font-family:'Mate SC','Arial';}
.font-selector-Medula-One{font-family:'Medula One','Arial';}
.font-selector-Merienda-One{font-family:'Merienda One','Arial';}
.font-selector-Montaga{font-family:'Montaga','Arial';}
.font-selector-Mr-Bedfort{font-family:'Mr Bedfort','Arial';}
.font-selector-Mukta{font-family:'Mukta','Arial';}
.font-selector-News-Cycle{font-family:'News Cycle','Arial';}
.font-selector-Nothing-You-Could-Do{font-family:'Nothing You Could Do','Arial';}
.font-selector-Noticia-Text{font-family:'Noticia Text','Arial';}
.font-selector-Noto-Sans{font-family:'Noto Sans','Arial';}
.font-selector-Numans{font-family:'Numans','Arial';}
.font-selector-Nunito{font-family:'Nunito','Arial';}
.font-selector-Nunito-Sans{font-family:'Nunito Sans','Arial';}
.font-selector-Offside{font-family:'Offside','Arial';}
.font-selector-Open-Sans{font-family:'Open Sans','Arial';}
.font-selector-Oswald{font-family:'Oswald','Arial';}
.font-selector-Overlock-SC{font-family:'Overlock SC','Arial';}
.font-selector-Pacifico{font-family:'Pacifico','Arial';}
.font-selector-Passero-One{font-family:'Passero One','Arial';}
.font-selector-Pathway-Gothic-One{font-family:'Pathway Gothic One','Arial';}
.font-selector-Patua-One{font-family:'Patua One','Arial';}
.font-selector-Permanent-Marker{font-family:'Permanent Marker','Arial';}
.font-selector-Plaster{font-family:'Plaster','Arial';}
.font-selector-Play{font-family:'Play','Arial';}
.font-selector-Playfair-Display{font-family:'Playfair Display','Arial';}
.font-selector-Poller-One{font-family:'Poller One','Arial';}
.font-selector-Pompiere{font-family:'Pompiere','Arial';}
.font-selector-Poppins{font-family:'Poppins','Arial';}
.font-selector-Port-Lligat-Sans{font-family:'Port Lligat Sans','Arial';}
.font-selector-Port-Lligat-Slab{font-family:'Port Lligat Slab','Arial';}
.font-selector-Prociono{font-family:'Prociono','Arial';}
.font-selector-Questrial{font-family:'Questrial','Arial';}
.font-selector-Quicksand{font-family:'Quicksand','Arial';}
.font-selector-Raleway{font-family:'Raleway','Arial';}
.font-selector-Rammetto-One{font-family:'Rammetto One','Arial';}
.font-selector-Righteous{font-family:'Righteous','Arial';}
.font-selector-Roboto{font-family:'Roboto','Arial';}
.font-selector-Roboto-Mono{font-family:'Roboto Mono','Arial';}
.font-selector-Rochester{font-family:'Rochester','Arial';}
.font-selector-Ropa-Sans{font-family:'Ropa Sans','Arial';}
.font-selector-Ruluko{font-family:'Ruluko','Arial';}
.font-selector-Sail{font-family:'Sail','Arial';}
.font-selector-Shadows-Into-Light{font-family:'Shadows Into Light','Arial';}
.font-selector-Shadows-Into-Light-Two{font-family:'Shadows Into Light Two','Arial';}
.font-selector-Slabo-27px{font-family:'Slabo 27px','Arial';}
.font-selector-Slackey{font-family:'Slackey','Arial';}
.font-selector-Sofia{font-family:'Sofia','Arial';}
.font-selector-Source-Code-Pro{font-family:'Source Code Pro','Arial';}
.font-selector-Source-Sans-Pro{font-family:'Source Sans Pro','Arial';}
.font-selector-Source-Serif-Pro{font-family:'Source Serif Pro','Arial';}
.font-selector-Spinnaker{font-family:'Spinnaker','Arial';}
.font-selector-Squada-One{font-family:'Squada One','Arial';}
.font-selector-Strait{font-family:'Strait','Arial';}
.font-selector-Tauri{font-family:'Tauri','Arial';}
.font-selector-Telex{font-family:'Telex','Arial';}
.font-selector-Text-Me-One{font-family:'Text Me One','Arial';}
.font-selector-Titillium-Web{font-family:'Titillium Web','Arial';}
.font-selector-Trykker{font-family:'Trykker','Arial';}
.font-selector-Tulpen-One{font-family:'Tulpen One','Arial';}
.font-selector-Ubuntu-Condensed{font-family:'Ubuntu Condensed','Arial';}
.font-selector-Unlock{font-family:'Unlock','Arial';}
.font-selector-Vampiro-One{font-family:'Vampiro One','Arial';}
.font-selector-Viga{font-family:'Viga','Arial';}
.font-selector-Vollkorn{font-family:'Vollkorn','Arial';}
.font-selector-Voltaire{font-family:'Voltaire','Arial';}
.font-selector-Wendy-One{font-family:'Wendy One','Arial';}
.font-selector-Yanone-Kaffeesatz{font-family:'Yanone Kaffeesatz','Arial';}

.existing-font{background-color: #D3F8F4;}
