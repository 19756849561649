@import 'Editor/_wickbrand.scss';

$canvas-transforms-size: 40px;

.canvas-transforms-widget {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: $canvas-transforms-size;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  background-color: $editor-primary-outline;
  border-radius: 4px;
}
.canvas-transforms-widget.mobile {
  margin-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#canvas-zoom-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#zoom-tool-container {
  width: $canvas-transforms-size;
  height: $canvas-transforms-size;
}

.canvas-transform-wick-button {
  border-radius: 0px; 
}

.zoom-tool {
  padding-left: 0px !important; 
  padding-right: 0px !important;
}

.canvas-transform-item {
  padding: 2px;
}

.canvas-transform-button {
  width: $canvas-transforms-size;
  height: $canvas-transforms-size;
  padding: 3px;
  padding-left: 1.5px;
  padding-right: 1.5px; 
}

.thin-transform-button {
  width: calc(#{$canvas-transforms-size} * .6); 
}

.zoom-out-button {
  padding-left: 0px;
}

.zoom-in-button {
  padding-right: 0px;
}

.canvas-transform-icon {
  width: 90%; 
}

.play-button {
  width: 100%; 
  height: 100%;
  padding: 3px; 
}

.play-button-container {
  width: calc(#{$canvas-transforms-size} * 1.5);
  height: calc(#{$canvas-transforms-size} * 1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $editor-primary-outline;
  border-radius: 30px; 
}

.transforms-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.transforms-container:first-child {
  padding-left: 2px; 
}
