/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

 @import 'Editor/_wickbrand.scss';

.mobile-menu-close {
    position: absolute;
    right: 15px;
    top: 15px;
    min-width: 30px;
    min-height: 30px;
}

.mobile-menu-close-icon {
    width: 30px;
}

.no-bg {
    background: none;
}

.mobile-menu-icon {
    width: 60px;
    height: 60px;
    fill: white;
}

.mobile-menu-options-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
}

.mobile-menu-mobile-body {
    height: 100%;
}

.mobile-menu-option-text {
    font-family: "Nunito";
    color: white;
    font-size: 40px;
    text-align: left;
    position: relative;
    left: 10%;
    width: 70%;
    height: 60%;
}

.mobile-menu-option {
    max-height: 20% !important;
}

.mobile-menu-button {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
}