/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-script-window-row-container {
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px; }

.inspector-script-window-container {
  width: 100%;
  height: 100px;
  background-color: #4A4A4A;
  border-radius: 3px; }

.inspector-script-window-container {
  height: 100%;
  padding-bottom: 5px; }

.inspector-script-window-header {
  width: 100%;
  height: 25px;
  background-color: #3B3B3B;
  color: #ffffff;
  text-align: center;
  vertical-align: center;
  border-radius: 3px 3px 0px 0px; }

.inspector-add-script-button {
  height: 25px;
  width: 100%; }

.inspector-script-window-row-name {
  width: calc(100% - 25px - 4px);
  height: 100%;
  background-color: #303030;
  color: white;
  border-radius: 3px;
  overflow: hidden;
  display: flex; }

.inspector-script-window-row-edit {
  width: 25px;
  height: 100%;
  background-color: #303030;
  color: white;
  border-radius: 3px; }

.inspector-script-window-row-delete {
  width: 25px;
  height: 100%;
  background-color: #303030;
  color: white;
  border-radius: 3px; }

.inspector-script-window-row-color-bar {
  height: 100%;
  width: 5px;
  margin-right: 5px; }

.inspector-script-window-row-color-bar.blue-bar {
  background-color: #05B8FF; }

.inspector-script-window-row-color-bar.green-bar {
  background-color: #29F1A3; }

.inspector-script-window-row-color-bar.yellow-bar {
  background-color: #FFE243; }
