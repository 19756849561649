/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$play-button-size: 40px;

.audio-player-container {
    background-color: $editor-primary;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    padding-top: 5px;
    padding-left: 5px;
}

.playbutton {
    display: inline-flex;
    flex-direction: column;
    width: $play-button-size;
    height: $play-button-size;

}

.controls {
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - #{$play-button-size});
    height: 90%;
    vertical-align: top;
}

.control {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.info-text {
    color: $editor-primary-text;
    text-align: center;
    overflow: hidden;
}

.no-background {
    background: none;
}