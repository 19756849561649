/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

.media-export-modal-body {
  width: 300px;
  min-width: 240px;  
  height: 160px;
  padding: 20px; 
}

.media-export-modal-content {
  padding-top: 15px;
  width: 100%;
  height: 100%;
}

#media-export-modal-title {
  font-size: 18px;
  color: $editor-modal-text-gray;
  font-weight: bold; 
  text-align: left;
  width: 100%; 
}

#media-export-modal-subtitle {
  font-size: 16px;
  color: $editor-modal-text-gray;
  padding-bottom: 4px;
}

#media-export-modal-status-message {
  font-size: 14px;
  color: $editor-modal-text-gray;
}
