
.theme-default {
    --border-radius-primary: 8px;
    --border-radius-secondary: 4px;

    /* Main Colors */
    --wick-primary: #303030;
    --wick-secondary: #3B3B3B;
    --wick-tertiary: #706d6d;
    --wick-disabled: #666666;


    /* Text Colors */
    --wick-text-primary: white;
    --wick-text-secondary: #BDBDBD;
    --wick-text-tertiary: #919191;
    --wick-text-disabled: white;
    --wick-text-event: black;

    /* Events */
    --wick-event-mouse: #01C094;
    --wick-event-keyboard: #E9AA02;
    --wick-event-timeline: #00A1E0;

    --wick-event-mouse-selected: #29F1A3;
    --wick-event-keyboard-selected: #FFE243;
    --wick-event-timeline-selected: #05B8FF;

    /* Theme Colors */
    --wick-red: #FF7878;
    --wick-red-light: #ff5c5c;
    
    --wick-green: #09F197;
    --wick-green-light: #19ffa7;

    --wick-yellow: #F0CC00;
    --wick-yellow-light: #ffde20;

    --wick-light-blue: #92D8FF;
    --wick-light-blue-light: #74ceff;

    --wick-purple: #C987FA;
    --wick-purple-light: #d49bff;

    --wick-teal: #26DED2;
    --wick-teal-light: #43fff2;

    --wick-pink: #FF66F0;
    --wick-pink-light: #ff80f2;

    --wick-error: rgba(255, 0, 0, .6);


    /* Shadows */
    --wick-box-shadow: 2px 2px 8px var(--wick-primary); 
}