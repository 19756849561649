/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$asset-height: 28px;
$asset-margin: 4px;

.asset-buttons-container {
  margin-bottom: $asset-margin;
  margin-top: $asset-margin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.asset-button {
  height: $asset-height;
  //margin-right: $asset-margin;
  //width:20%;
  padding: none;
  margin-right: $asset-margin;
}
.add {
  width: calc(100% - 25px - 2 * #{$asset-margin});
}
.audio-player {
  width: calc(100% - 25px - 2 * #{$asset-margin});
  height: $asset-height;
}
.delete {
  padding: none;
  width: 25px;
}
.select {
  color: unset;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding-left: 0;
}

.asset-item {
  border: none;
  transition: .2s;
  width: calc(100% - 2 * #{$asset-margin});
  //height: $asset-height;
  line-height: $asset-height;
  background-color: $editor-secondary;
  color: #FFFFFF; //$editor-secondary-text;
  margin-top: $asset-margin;
  margin-left: $asset-margin;
  margin-right: $asset-margin;
  padding-left: $asset-margin;
  padding-right: $asset-margin;
  border-radius: $interface-roundness-primary;
  overflow: hidden;
  text-align: left;
  vertical-align: center;
}

.asset-item:hover {
  background-color: $editor-primary;
  color: #FFFFFF; //$editor-primary-property-text;
  cursor: grab;
}

.asset-icon {
  display: inline-flex;
  width: calc(#{$asset-height} * .8);
  height: calc(#{$asset-height} * .8);
  margin-right: 5px;
}

.asset-name-text {
  margin-left: 4px;
  white-space: nowrap;
  overflow: scroll;
  height: 100%;
  overflow: hidden;
}

.asset-item.asset-selected {
  border: 2px solid $editor-green-highlight;
  margin: calc(#{$asset-margin} - 2px);
  transition: .2s;
  background-color: $editor-primary;
  color: #FFFFFF; //$editor-primary-property-text;
}