
.editor-info-modal-container {
    width: 250px;
    height: 325px;
}

.editor-info-icon {
    width: 100px;
    height: 100px; 
    margin: 0 auto; 
    margin-bottom: 8px;
}

.editor-info-name {
    font-size: 24px; 
    color: white;
    text-align: center;
}

.editor-info-version {
    color: white;
    justify-content: center;
    text-align: center;
}