.simple-settings-modal-container {
    padding: 16px;
    padding-bottom: 32px;
    width: 240px;
}

.simple-settings-modal-title {
    font-size: 24px;
    color: white;
    margin-top: 16px;
}

.simple-settings-modal-body {
    display: flex;
    flex-direction: column;
}

.simple-settings-form-row {
    width: 100%;
    margin-bottom: 8px; 
}

.simple-settings-input-label {
    color: white;
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
}

.simple-settings-modal-footer {
    display: flex;
    width: 100%;
    height: 35px;
    justify-content: center;
    margin-top: 16px;
}

.simple-settings-action-button {
    width: 40%;
    margin: 8px;
}

.simple-settings-input {
    height: 32px;
    border-radius: 4px;
    border: none;
    width: 100%;
}

.simple-settings-dual-input {
    width: 45%;
}

.simple-settings-dual-form-row {
    display: flex;
    justify-content: space-between;
}